import {Card, Heading, Text} from '@chanzuckerberg/eds';
import {PlatformGoogleLoginButton} from '@chanzuckerberg/edu-platform-launch';
import {redirect, type LoaderFunctionArgs} from '@remix-run/server-runtime';
import Wave from '~/components/Wave/Wave';
import {getAccountId} from '~/utils/sessions.server';

export async function loader({request, params}: LoaderFunctionArgs) {
  if (await getAccountId(request)) {
    redirect('/');
  }
  return null;
}

export default function LoginPage() {
  return (
    <div className="h-full bg-neutral-default">
      <div className="h-5/6 bg-brand-primary-default pt-[10%]">
        <Card
          className="m-auto h-fit w-fit px-24 py-16 text-center"
          elevation="raised"
        >
          <Card.Header className="mb-12">
            <Heading as="h2" preset="headline-secondary-md-bold">
              Lit Booster
            </Heading>
          </Card.Header>
          <Card.Body className="mb-8">
            <Text className="pb-4" preset="title-md">
              Sign in with your Google account
            </Text>
            <PlatformGoogleLoginButton />
          </Card.Body>
          <Card.Footer className="mt-4">
            <Text preset="body-md">Thanks for co-creating with us.</Text>
            <Text preset="body-md">We can’t wait to hear what you think. </Text>
          </Card.Footer>
        </Card>
      </div>
      <Wave isPurpleTop={true}></Wave>
    </div>
  );
}
